@font-face {
    font-family: Enriqueta;
    src: url('./assets/Enriqueta-Regular.ttf');
    font-weight: normal;
}

@font-face {
    font-family: Enriqueta;
    src: url('./assets/Enriqueta-Bold.ttf');
    font-weight: bold;
}

html, body {
    font-family: Enriqueta;
}

body {
    background-color: rgb(255, 253, 253);
    background-size: auto;
    background-position: center top;
    background-repeat: repeat;
    background-image: url("./assets/bg.webp");
}

a, a:link, a:visited {
    color: #4e3723;
}

.contact {
    font-size: 1.2rem;
}

#openGallery {
    display: inline-block;
    background-image: url(./assets/thumbnail.jpg);
    width: 720px;
    height: 478px;
}